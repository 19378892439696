//tags:[memes, anime, sound effect, music, movie, video game]

module.exports = {
  sounds: {
    itiswednesday: {
      title: "It is Wednesday My Dude",
      url: "https://www.myinstants.com/media/sounds/it-is-wednesday-my-dudes-vine.mp3",
      commandName: "itiswednesday",
      tags: ["memes"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    oneeternitylater: {
      title: "One Eternity Later",
      url: "https://www.myinstants.com/media/sounds/one-eternity-later.mp3",
      commandName: "oneeternitylater",
      tags: ["memes", "sound effect"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    thatsprettygood: {
      title: "Hey, That's Pretty Good",
      url: "https://www.myinstants.com/media/sounds/hey-thats-pretty-good.mp3",
      commandName: "thatsprettygood",
      tags: ["memes"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    suspenserising: {
      title: "Suspense",
      url: "https://www.myinstants.com/media/sounds/suspense-sound-effect-imovie.mp3",
      commandName: "suspenserising",
      tags: ["sound effect"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    helloitsme: {
      title: "Hello, It's Me",
      url: "https://www.myinstants.com/media/sounds/helloitsmemusic.mp3",
      commandName: "helloitsme",
      tags: ["memes", "music"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    holdupwaitaminute: {
      title: "Hold Up, Wait a Minute",
      url: "https://www.myinstants.com/media/sounds/untitled_OTuNHvt.mp3",
      commandName: "holdupwaitaminute",
      tags: ["memes"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    clapping: {
      title: "Clapping",
      url: "https://www.myinstants.com/media/sounds/aplausos_2.mp3",
      commandName: "clapping",
      tags: ["sound effect"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    whyarewestillhere: {
      title: "Why Are We Still Here",
      url: "https://www.myinstants.com/media/sounds/why-are-we-still-here-kaz-short-speech-10.mp3",
      commandName: "whyarewestillhere",
      tags: ["memes"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    tuturu: {
      title: "Tuturu",
      url: "https://www.myinstants.com/media/sounds/tuturu_1.mp3",
      commandName: "tuturu",
      tags: ["anime"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    wowkongouratulations: {
      title: "Wow, Kongouratulations",
      url: "https://www.myinstants.com/media/sounds/kc78v26.mp3",
      commandName: "wowkongouratulations",
      tags: ["anime"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    hagoteem: {
      title: "HAH! GOTEEM",
      url: "https://www.myinstants.com/media/sounds/ha-got-eeem.mp3",
      commandName: "hagoteem",
      tags: ["memes"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    themoreyouknow: {
      title: "The More You Know",
      url: "https://www.myinstants.com/media/sounds/the-more-you-know.mp3",
      commandName: "themoreyouknow",
      tags: ["memes"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    pokemonheal: {
      title: "Pokemon Heal",
      url: "https://www.myinstants.com/media/sounds/111-pokemon-recovery.mp3",
      commandName: "pokemonheal",
      tags: ["sound effect", "video game"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    twohourslater: {
      title: "Two Hours Later",
      url: "https://www.myinstants.com/media/sounds/spongebob-two-hours-later-2019-download-link.mp3",
      commandName: "towhourslater",
      tags: ["memes", "sound effect"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    wiisports: {
      title: "Wii Sports",
      url: "https://www.myinstants.com/media/sounds/wii-sports-wii-sports-theme-y.mp3",
      commandName: "wiisports",
      tags: ["memes", "video game"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    mmmmonkey: {
      title: "MMM, Monkey",
      url: "https://www.myinstants.com/media/sounds/tmpb48eyu7x.mp3",
      commandName: "mmmmonkey",
      tags: ["memes"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    dancemonkeydance: {
      title: "Dance, Monkey, Dance!",
      url: "https://www.myinstants.com/media/sounds/o5vmhjj7lye.mp3",
      commandName: "dancemonkeydance",
      tags: ["memes"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    monkeyhut: {
      title: "Monkey Hut",
      url: "https://www.myinstants.com/media/sounds/monkey_oT2X3a2.mp3",
      commandName: "monkeyhut",
      tags: ["memes"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    goodbyemonkey: {
      title: "Goodbye Monkey",
      url: "https://www.myinstants.com/media/sounds/goodbye-monkey.mp3",
      commandName: "goodbyemonkey",
      tags: ["memes"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    tacobell: {
      title: "Taco Bell Dong",
      url: "https://www.myinstants.com/media/sounds/taco-bell-bong-sfx.mp3",
      commandName: "tacobell",
      tags: ["memes", "sound effect"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    whatdadogdoin: {
      title: "What Da Dog Doin",
      url: "https://www.myinstants.com/media/sounds/yt1s_wU4BGgD.mp3",
      commandName: "whatdadogdoin",
      tags: ["memes"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    animewow: {
      title: "Anime Wow",
      url: "https://www.myinstants.com/media/sounds/anime-wow-sound-effect.mp3",
      commandName: "animewow",
      tags: ["memes", "anime"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    tobecontinued: {
      title: "To Be Continued",
      url: "https://www.myinstants.com/media/sounds/untitled_1071.mp3",
      commandName: "tobecontinued",
      tags: ["memes"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    nicecock: {
      title: "Nice Cock",
      url: "https://www.myinstants.com/media/sounds/wii-sports-bowling-nice-cock.mp3",
      commandName: "nicecock",
      tags: ["memes"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    realisticknockingsound: {
      title: "Realistic Knocking Sound",
      url: "https://www.myinstants.com/media/sounds/crazy-realistic-knocking-sound-troll-twitch-streamers_small_mYNcUdA.mp3",
      commandName: "realisticknockingsound",
      tags: ["sound effect"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    ilikeyacutg: {
      title: "I Like Ya Cut, G",
      url: "https://www.myinstants.com/media/sounds/i-like-ya-cut-g-1.mp3",
      commandName: "ilikeyacutg",
      tags: ["memes"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    theyaskyouhowyouare: {
      title: "They Ask You How You Are",
      url: "https://www.myinstants.com/media/sounds/they-ask-you-how-you-are-and-you-just-have-to-say-that-youre-fine-sound-effect_IgYM1CV.mp3",
      commandName: "theyaskyouhowyouare",
      tags: ["memes"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    memefinal: {
      title: "Meme Final",
      url: "https://www.myinstants.com/media/sounds/meme-de-creditos-finales.mp3",
      commandName: "memefinal",
      tags: ["memes"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    dejavu: {
      title: "DejaVu",
      url: "https://www.myinstants.com/media/sounds/deja-vu.mp3",
      commandName: "dejavu",
      tags: ["memes"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    inception: {
      title: "Inception",
      url: "https://www.myinstants.com/media/sounds/inceptionbutton.mp3",
      commandName: "inception",
      tags: ["memes", "movie"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    wasted: {
      title: "Wasted",
      url: "https://www.myinstants.com/media/sounds/gta-v-death-sound-effect-102.mp3",
      commandName: "wasted",
      tags: ["memes", "video game"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    seinfeldthemesong: {
      title: "Seinfeld Theme Song",
      url: "https://www.myinstants.com/media/sounds/seinfeld-theme_1.mp3",
      commandName: "seinfeldthemesong",
      tags: ["memes"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    slumberthatbrothergone: {
      title: "Slumber That Brother Gone",
      url: "https://www.myinstants.com/media/sounds/slumber-that-brother-gone-meme_pXziXJ1.mp3",
      commandName: "slumberthatbrothergone",
      tags: ["memes"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    spongebobfail: {
      title: "SpongeBob Fail",
      url: "https://www.myinstants.com/media/sounds/spongebob-fail.mp3",
      commandName: "spongebobfail",
      tags: ["memes"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    crabrave: {
      title: "Crab Rave",
      url: "https://www.myinstants.com/media/sounds/original_zeowGW1.mp3",
      commandName: "crabrave",
      tags: ["memes", "music"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    thisguystinks: {
      title: "This Guy Stinks",
      url: "https://www.myinstants.com/media/sounds/oh-brother-this-guy-stinks.mp3",
      commandName: "thisguystinks",
      tags: ["memes"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    sickomodefx: {
      title: "Sicko Mode FX",
      url: "https://www.myinstants.com/media/sounds/the-beginning-of-sicko-mode-sound-effect-for-memes_xAcUeuI.mp3",
      commandName: "sickomodefx",
      tags: ["memes"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    gnome: {
      title: "Gnome",
      url: "https://www.myinstants.com/media/sounds/im-a-gnome-meme-sound-effect-woo.mp3",
      commandName: "gnome",
      tags: ["memes"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    titanicflute: {
      title: "Titanic Flute",
      url: "https://www.myinstants.com/media/sounds/titanic-parody-mp3cut.mp3",
      commandName: "titanicflute",
      tags: ["memes", "movie"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    carelesswhisper: {
      title: "Careless Whisper",
      url: "https://www.myinstants.com/media/sounds/careless_whispers.mp3",
      commandName: "carelesswhisper",
      tags: ["music"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    howcouldthishappentome: {
      title: "How Could This Happen To Me?",
      url: "https://www.myinstants.com/media/sounds/how.mp3",
      commandName: "howcouldthishappentome",
      tags: ["music"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    oniichan: {
      title: "Onii Chan",
      url: "https://www.myinstants.com/media/sounds/screenrecord_2015-06-17-19-00-59.mp3",
      commandName: "oniichan",
      tags: ["anime"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    r2d2scream: {
      title: "R2D2 Scream",
      url: "https://www.myinstants.com/media/sounds/r2d2_scream_converted.mp3",
      commandName: "r2d2scream",
      tags: ["sound effect", "movie"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    oohwahahahah: {
      title: "Oooh Wah Ah Ah Ah",
      url: "https://www.myinstants.com/media/sounds/david-oohwahahahah.mp3",
      commandName: "oohwahahahah",
      tags: ["sound effect", "music"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    chugjugwithyou: {
      title: "Chug Jug With You",
      url: "https://www.myinstants.com/media/sounds/qqrlvfre9au-00_00_16.mp3",
      commandName: "chugjugwithyou",
      tags: ["memes"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    shootingstars: {
      title: "Shooting Stars",
      url: "https://www.myinstants.com/media/sounds/untitled-1_qPskhfn.mp3",
      commandName: "shootingstars",
      tags: ["memes", "music"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    fitnessgrampacertest: {
      title: "The Fitness Gram Pacer Test",
      url: "https://www.myinstants.com/media/sounds/fitnessgram.mp3",
      commandName: "fitnessgrampacertest",
      tags: ["memes"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    itemgetzelda: {
      title: "Get Item Zelda",
      url: "https://www.myinstants.com/media/sounds/139-item-catch.mp3",
      commandName: "itemgetzelda",
      tags: ["sound effect", "video game"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    xfiles: {
      title: "X Files",
      url: "https://www.myinstants.com/media/sounds/expedientes-secretos-x-musica22.mp3",
      commandName: "xfiles",
      tags: ["memes"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    hethiccboi: {
      title: "He Thicc Boi",
      url: "https://www.myinstants.com/media/sounds/tmp_ubrotvw.mp3",
      commandName: "hethiccboi",
      tags: ["memes"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    ussranthem: {
      title: "USSR Anthem",
      url: "https://www.myinstants.com/media/sounds/ussr-anthem-short2.mp3",
      commandName: "ussranthem",
      tags: ["memes", "music"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    victoryscreech: {
      title: "Victory Screech",
      url: "https://www.myinstants.com/media/sounds/victoryscreech.mp3",
      commandName: "victoryscreech",
      tags: ["memes"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    whip: {
      title: "Whip Crack",
      url: "https://www.myinstants.com/media/sounds/crack_the_whip.mp3",
      commandName: "whip",
      tags: ["sound effect"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    reeeeee: {
      title: "Reeeeeee",
      url: "https://www.myinstants.com/media/sounds/reeeeeeeee.mp3",
      commandName: "reeeeee",
      tags: ["memes"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    hellodarkness: {
      title: "Hello Darkness, My Old Friend",
      url: "https://www.myinstants.com/media/sounds/hellodarknessmyoldfriend.mp3",
      commandName: "hellodarkness",
      tags: ["memes", "music"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    yalikejazz: {
      title: "Ya Like Jazz?",
      url: "https://www.myinstants.com/media/sounds/ya-like-jazz.mp3",
      commandName: "yalikejazz",
      tags: ["memes", "movie"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    diabetus: {
      title: "Diabetus",
      url: "https://www.myinstants.com/media/sounds/wilford-brimley-diabetes.mp3",
      commandName: "diabetus",
      tags: ["memes"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    deadmananime: {
      title: "Deadman Anime",
      url: "https://www.myinstants.com/media/sounds/deadman-fx.mp3",
      commandName: "deadmananime",
      tags: ["memes", "anime"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    nezukogrowl: {
      title: "Nezuko Growl",
      url: "https://www.myinstants.com/media/sounds/nezuko-growl.mp3",
      commandName: "nezukogrowl",
      tags: ["anime"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    beans: {
      title: "Beans",
      url: "https://www.soundboardguy.com/wp-content/uploads/2021/07/bean.mp3",
      commandName: "beans",
      tags: ["memes"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    goodjobanime: {
      title: "Good Job Anime",
      url: "https://www.myinstants.com/media/sounds/good-job_d15pHHg.mp3",
      commandName: "goodjobanime",
      tags: ["anime"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    bekfast: {
      title: "Bekfast",
      url: "https://www.myinstants.com/media/sounds/downsyndrome-breakfast-kid-face-version.mp3",
      commandName: "bekfast",
      tags: ["memes"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    angryanimegirl: {
      title: "Angry Anime Girl",
      url: "https://www.myinstants.com/media/sounds/grisaia3-2018-01-02-02-25-25-867.mp3",
      commandName: "angryanimegirl",
      tags: ["anime"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    ineedahero: {
      title: "I Need a Hero",
      url: "https://www.myinstants.com/media/sounds/hero_c3tEI4U.mp3",
      commandName: "ineedahero",
      tags: ["memes", "music"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    minionshello: {
      title: "Minions Hello",
      url: "https://www.myinstants.com/media/sounds/hello_D4GzkfK.mp3",
      commandName: "minionshello",
      tags: ["memes", "movie"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    yodelingwalmartkid: {
      title: "Yodeling Walmart Kid",
      url: "https://www.myinstants.com/media/sounds/yodeling-walmart-kid-edm-trap-remix-bass-boosted-audiotrimmer.mp3",
      commandName: "yodelingwalmartkid",
      tags: ["memes", "music"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    phonevibrate: {
      title: "Phone Vibrate",
      url: "https://www.myinstants.com/media/sounds/vibrate-f.mp3",
      commandName: "phonevibrate",
      tags: ["sound effect"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    armsoftheangel: {
      title: "In the Arms of the Angels",
      url: "https://www.myinstants.com/media/sounds/arms-of-the-angels.mp3",
      commandName: "armsoftheangel",
      tags: ["memes", "music"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    goodtaters: {
      title: "Good Taters",
      url: "https://www.myinstants.com/media/sounds/what-we-need.mp3",
      commandName: "goodtaters",
      tags: ["memes", "music"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    gasp: {
      title: "Gasp",
      url: "https://www.myinstants.com/media/sounds/gasp-2.mp3",
      commandName: "gasp",
      tags: ["sound effect"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    popcat: {
      title: "Pop Cat",
      url: "https://www.myinstants.com/media/sounds/cat-mouth-noise-192-kbps.mp3",
      commandName: "popcat",
      tags: ["memes"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    uwusong: {
      title: "UwU Song",
      url: "https://www.myinstants.com/media/sounds/uwubassboost-1.mp3",
      commandName: "uwusong",
      tags: ["memes", "anime"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    callanambulance: {
      title: "Call An Ambulance",
      url: "https://www.myinstants.com/media/sounds/call-an-ambulance-but-not-for-me-original-extracted-from-facebook-audiotrimmer.mp3",
      commandName: "callanambulance",
      tags: ["memes"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    dogofwisdom: {
      title: "Dog of Wisdom",
      url: "https://www.myinstants.com/media/sounds/dog-of-wisdom-12.mp3",
      commandName: "dogofwisdom",
      tags: ["memes"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    iwanttobemonkey: {
      title: "I Want To Be Monkey",
      url: "https://www.myinstants.com/media/sounds/goback.mp3",
      commandName: "iwanttobemonkey",
      tags: ["memes"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    loudindian: {
      title: "Loud Indian",
      url: "https://www.myinstants.com/media/sounds/loud-indian-music-audiotrimmer.mp3",
      commandName: "loudindian",
      tags: ["memes"],
      volume: 0.9,
    },
    miichannel: {
      title: "Mii Channel",
      url: "https://www.myinstants.com/media/sounds/original-bongo-cat-mii-channel-theme-1.mp3",
      commandName: "miichannel",
      tags: ["memes", "video game"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    pickelchin: {
      title: "Pickel Chin",
      url: "https://www.myinstants.com/media/sounds/im-bout-to-get-to-pickle-chin-ass-boi-audiotrimmer.mp3",
      commandName: "pickelchin",
      tags: ["memes"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    bingbingbong: {
      title: "Bing Bing Bong",
      url: "https://www.myinstants.com/media/sounds/donald-trump-bing-bong-sound-effect_lTRFmBB.mp3",
      commandName: "bingbingbong",
      tags: ["memes"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    pizzatime: {
      title: "Pizza Time",
      url: "https://www.myinstants.com/media/sounds/pizza-time.mp3",
      commandName: "pizzatime",
      tags: ["memes", "movie"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    shotoniphone: {
      title: "Shot On iPhone",
      url: "https://www.myinstants.com/media/sounds/shot-on-iphone-meme-template-hd-audiotrimmer.mp3",
      commandName: "shotoniphone",
      tags: ["memes"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    bassnoise: {
      title: "Bass Noise",
      url: "https://www.myinstants.com/media/sounds/bass-boost-sound-effect.mp3",
      commandName: "bassnoise",
      tags: ["memes"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    longfart: {
      title: "Long Fart",
      url: "https://www.myinstants.com/media/sounds/sstg-8682b015-dddb-43c3-8e0f-998ddb021be8-1.mp3",
      commandName: "longfart",
      tags: ["memes"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    respectthedripkaren: {
      title: "Respect the Drip, Karen",
      url: "https://www.myinstants.com/media/sounds/respect-the-drip-karen-mp3.mp3",
      commandName: "respectthedripkaren",
      tags: ["memes"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    runninginthe90s: {
      title: "Running in the 90s",
      url: "https://www.myinstants.com/media/sounds/tmpdbnm_5a3.mp3",
      commandName: "runninginthe90s",
      tags: ["memes"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    watchasay: {
      title: "MMM Whatcha Say",
      url: "https://www.myinstants.com/media/sounds/mmm-whatcha-say.mp3",
      commandName: "watchasay",
      tags: ["memes", "music"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    pillarmen: {
      title: "Pillarmen",
      url: "https://www.myinstants.com/media/sounds/jojos-bizarre-adventure-ay-ay-ay-ay-_-sound-effect.mp3",
      commandName: "pillarmen",
      tags: ["memes", "anime"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    mytralala: {
      title: "You Touch My Tralala",
      url: "https://www.myinstants.com/media/sounds/tralala_1.mp3",
      commandName: "mytralala",
      tags: ["memes"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    turtlesex: {
      title: "Turtle Sex",
      url: "https://www.myinstants.com/media/sounds/tortuuga-.mp3",
      commandName: "turtlesex",
      tags: ["memes", "sound effect"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    wryyy: {
      title: "Wryyy",
      url: "https://www.myinstants.com/media/sounds/dio-wryyy.mp3",
      commandName: "wryyy",
      tags: ["anime"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    utini: {
      title: "Utini",
      url: "https://www.myinstants.com/media/sounds/utini.mp3",
      commandName: "utini",
      tags: ["memes"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    nya: {
      title: "Nya",
      url: "https://www.myinstants.com/media/sounds/nyaa-3.mp3",
      commandName: "nya",
      tags: ["anime"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    attackontitan: {
      title: "Attack On Titan",
      url: "https://www.myinstants.com/media/sounds/shingeki-no-kyojin-ost-attack-on-titan2.mp3",
      commandName: "attackontitan",
      tags: ["anime"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    pokemonbattle: {
      title: "Pokemon Battle",
      url: "https://www.myinstants.com/media/sounds/pokemon-battle.mp3",
      commandName: "pokemonbattle",
      tags: ["sound effect", "video game"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    nepunepu: {
      title: "Nepu Nepu",
      url: "https://www.myinstants.com/media/sounds/nepu-nepu.mp3",
      commandName: "nepunepu",
      tags: ["anime"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    rerorero: {
      title: "Rero Rero",
      url: "https://www.myinstants.com/media/sounds/rero-rero-rero.mp3",
      commandName: "rerorero",
      tags: ["anime"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    jeopardy: {
      title: "Jeopardy Theme Song",
      url: "https://www.myinstants.com/media/sounds/jeopardy-themelq.mp3",
      commandName: "jeopardy",
      tags: ["memes"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    yareyaredaze: {
      title: "Yare Yare Daze",
      url: "https://www.myinstants.com/media/sounds/yare-yare-daze_zAnWvX6.mp3",
      commandName: "yareyaredaze",
      tags: ["anime"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    underthewater: {
      title: "Under the Water",
      url: "https://www.myinstants.com/media/sounds/y2mate_HOnnyD0.mp3",
      commandName: "underthewater",
      tags: ["memes"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    damedane: {
      title: "Dame Da Ne",
      url: "https://www.myinstants.com/media/sounds/dame-da-ne.mp3",
      commandName: "damedane",
      tags: ["memes", "music"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    narutotrap: {
      title: "Naruto Trap",
      url: "https://www.myinstants.com/media/sounds/naruto-trap.mp3",
      commandName: "narutotrap",
      tags: ["memes", "anime"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    brrrrrr: {
      title: "Brrrrrr",
      url: "https://www.myinstants.com/media/sounds/xavier-wulf-psycho-pass-extreme-bass-boosted-2.mp3",
      commandName: "brrrrrr",
      tags: ["memes", "sound effect"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    jurassicpark: {
      title: "Jurassic Park",
      url: "https://www.myinstants.com/media/sounds/jurassic-park.mp3",
      commandName: "jurassicpark",
      tags: ["memes", "music", "movie"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    heroesneverdie: {
      title: "Heroes Never Die",
      url: "https://www.myinstants.com/media/sounds/overwatch-mercy-s-ultimate-heroes-never-die.mp3",
      commandName: "heroesneverdie",
      tags: ["memes", "sound effect"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    xuehuapiaopiao: {
      title: "Xue Hua Piao Piao",
      url: "https://www.myinstants.com/media/sounds/xue-hua-piao-piao.mp3",
      commandName: "xuehuapiaopiao",
      tags: ["memes", "music"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    uhohstinky: {
      title: "Uh Oh, Stinky",
      url: "https://www.myinstants.com/media/sounds/cut_cut_y2mate.mp3",
      commandName: "uhohstinky",
      tags: ["memes"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    guilestheme: {
      title: "Guile's Theme",
      url: "https://www.myinstants.com/media/sounds/cps2-guile-stage.mp3",
      commandName: "guilestheme",
      tags: ["memes", "music"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    madeinheaven: {
      title: "Made in Heaven",
      url: "https://www.myinstants.com/media/sounds/made_in_heaven_test_animationyoutubetomp3.mp3",
      commandName: "madeinheaven",
      tags: ["memes", "anime"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    xptrap: {
      title: "Windows XP Trap",
      url: "https://www.myinstants.com/media/sounds/musica_1.mp3",
      commandName: "xptrap",
      tags: ["memes", "music"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    rogerroger: {
      title: "Roger Roger",
      url: "https://www.myinstants.com/media/sounds/star-wars-b1-battle-droid_kampfdroide-roger-roger-sound.mp3",
      commandName: "rogerroger",
      tags: ["memes", "sound effect", "movie"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    what: {
      title: "Jon Tron What",
      url: "https://www.myinstants.com/media/sounds/jontron-what_McUdLBG.mp3",
      commandName: "what",
      tags: ["memes"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    doh: {
      title: "Doh",
      url: "https://www.myinstants.com/media/sounds/doh.swf.mp3",
      commandName: "doh",
      tags: ["memes", "sound effect"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    nyancat: {
      title: "Nyan Cat",
      url: "https://www.myinstants.com/media/sounds/nyan-cat_1.mp3",
      commandName: "nyancat",
      tags: ["memes", "anime"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    heisalegend: {
      title: "He Is A Legend",
      url: "https://www.myinstants.com/media/sounds/trump-legend.mp3",
      commandName: "heisalegend",
      tags: ["memes"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    wrong: {
      title: "Wrong",
      url: "https://www.myinstants.com/media/sounds/wrong_4wlzsjh.mp3",
      commandName: "wrong",
      tags: ["memes"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    howdareyou: {
      title: "How Dare You",
      url: "https://www.myinstants.com/media/sounds/new-project_x1Ilmq5.mp3",
      commandName: "howdareyou",
      tags: ["memes"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    okay: {
      title: "Okay",
      url: "https://www.myinstants.com/media/sounds/my-song-2_2.mp3",
      commandName: "okay",
      tags: ["memes", "sound effect"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    inmemumscar: {
      title: "In Me Mums Car",
      url: "https://www.myinstants.com/media/sounds/im-in-me-mums-car.mp3",
      commandName: "inmemumscar",
      tags: ["memes"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    noimnot: {
      title: "No I'm Not",
      url: "https://www.myinstants.com/media/sounds/no-im-not.mp3",
      commandName: "noimnot",
      tags: ["memes"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    readherbible: {
      title: "Read Her Bible",
      url: "https://www.myinstants.com/media/sounds/read-your.mp3",
      commandName: "readherbible",
      tags: ["memes"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    lalalala: {
      title: "La La La La",
      url: "https://www.myinstants.com/media/sounds/lalalalala.swf.mp3",
      commandName: "lalalala",
      tags: ["memes", "music"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    pikachu: {
      title: "Pikachu",
      url: "https://www.myinstants.com/media/sounds/pickachu.mp3",
      commandName: "pikachu",
      tags: ["sound effect", "video game"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    tenandayo: {
      title: "Ehe Te Nandayo",
      url: "https://www.myinstants.com/media/sounds/4k-ehe-te-nandayo-paimon-green-screen-update.mp3",
      commandName: "tenandayo",
      tags: ["memes", "anime"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    whatareyouaimingat: {
      title: "What Are You Aiming At?",
      url: "https://www.myinstants.com/media/sounds/steel_tv-what-are-you-aiming-at.mp3",
      commandName: "whatareyouaimingat",
      tags: ["memes"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    letsgo: {
      title: "Let's Go",
      url: "https://www.myinstants.com/media/sounds/vibez-lets-go.mp3",
      commandName: "letsgo",
      tags: ["music"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    skulltrumpet: {
      title: "Skull Trumpet",
      url: "https://www.myinstants.com/media/sounds/skullsound2.mp3",
      commandName: "skulltrumpet",
      tags: ["memes", "sound effect"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    mlgpwnage: {
      title: "MLG PWNAGE",
      url: "https://www.myinstants.com/media/sounds/swaggityswagger.mp3",
      commandName: "mlgpwnage",
      tags: ["memes"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    ohyeah: {
      title: "Oh Yeah",
      url: "https://www.myinstants.com/media/sounds/01-oh-yeah.mp3",
      commandName: "ohyeah",
      tags: ["memes", "sound effect"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    failhorn: {
      title: "Fail Horn",
      url: "https://www.myinstants.com/media/sounds/thepriceisright-loserhorns.mp3",
      commandName: "failhorn",
      tags: ["memes", "sound effect"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    neinneinnein: {
      title: "Nein Nein Nein",
      url: "https://www.myinstants.com/media/sounds/hitler-nein_1.mp3",
      commandName: "neinneinnein",
      tags: ["memes"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    iwillalwaysloveyou: {
      title: "I Will Always Love You",
      url: "https://www.myinstants.com/media/sounds/i-will-always-love-you-low.mp3",
      commandName: "iwillalwaysloveyou",
      tags: ["music"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    illuminati: {
      title: "Illuminati",
      url: "https://www.myinstants.com/media/sounds/damnson.mp3",
      commandName: "illuminati",
      tags: ["memes"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    yourenotthatguy: {
      title: "You're Not That Guy",
      url: "https://www.myinstants.com/media/sounds/youre-not-that-guy-full.mp3",
      commandName: "yourenotthatguy",
      tags: ["memes"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    canttouchthis: {
      title: "Can't Touch This",
      url: "https://www.myinstants.com/media/sounds/cantouchthis.mp3",
      commandName: "canttouchthis",
      tags: ["music"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    shutupandtakemymoney: {
      title: "Shut Up and Take My Money",
      url: "https://www.myinstants.com/media/sounds/shut-up_2.mp3",
      commandName: "shutupandtakemymoney",
      tags: ["memes"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    sussybakka: {
      title: "Sussy Bakka",
      url: "https://www.myinstants.com/media/sounds/sussy-bakka.mp3",
      commandName: "sussybakka",
      tags: ["memes"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    alientiktokmeme: {
      title: "Alien Tik Tok Meme",
      url: "https://www.myinstants.com/media/sounds/alien-meme-song-with-lyrics-patlamaya-devam-lyrics-alien-dancing-meme-mp3cut.mp3",
      commandName: "alientiktokmeme",
      tags: ["memes"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    howardthealien: {
      title: "Howard the Alien",
      url: "https://www.myinstants.com/media/sounds/howard-earrape_52Xy58b.mp3",
      commandName: "howardthealien",
      tags: ["memes", "music"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    dolphincensor: {
      title: "Dolphin Censor",
      url: "https://www.myinstants.com/media/sounds/spongebob-dolphin-censor.mp3",
      commandName: "dolphincensor",
      tags: ["memes", "sound effect"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    monstersinc: {
      title: "Monsters Inc",
      url: "https://www.myinstants.com/media/sounds/monsters_inc_themeer.mp3",
      commandName: "monstersinc",
      tags: ["movie", "memes"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    succ: {
      title: "Succ",
      url: "https://www.myinstants.com/media/sounds/succ.mp3",
      commandName: "Succ",
      tags: ["memes"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    ohnonono: {
      title: "Oh No No No",
      url: "https://www.myinstants.com/media/sounds/oh-no-no-no-no-laugh.mp3",
      commandName: "ohnonono",
      tags: ["memes"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    yodadeathsound: {
      title: "Yoda Death Sound",
      url: "https://www.myinstants.com/media/sounds/lego-yoda-death-sound-effect.mp3",
      commandName: "yodadeathsound",
      tags: ["memes", "video game"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    nuclearfart: {
      title: "Nuclear Fart",
      url: "https://www.myinstants.com/media/sounds/nuclear-fart-1.mp3",
      commandName: "nuclearfart",
      tags: ["memes"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    sitcomlaugh: {
      title: "Sitcom Laugh",
      url: "https://www.myinstants.com/media/sounds/sitcom-laughing-1.mp3",
      commandName: "sitcomlaugh",
      tags: ["sound effect"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    deeznutz: {
      title: "Deez Nutz",
      url: "https://www.myinstants.com/media/sounds/deez-nuts-got-eem-original-vine-mp3cut.mp3",
      commandName: "deeznutz",
      tags: ["memes"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    pufferfish: {
      title: "Pufferfish",
      url: "https://www.myinstants.com/media/sounds/aaughmp3.mp3",
      commandName: "pufferfish",
      tags: ["memes"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    wilhelmscream: {
      title: "Wilhelm Scream",
      url: "https://www.myinstants.com/media/sounds/wilhelmscream.mp3",
      commandName: "wilhelmscream",
      tags: ["sound effect"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    loudairhorn: {
      title: "Loud Airhorn",
      url: "https://www.myinstants.com/media/sounds/veryloudairhorn.mp3",
      commandName: "loudairhorn",
      tags: ["memes", "sound effect"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    defaultdance: {
      title: "Fortnite Default Dance",
      url: "https://www.myinstants.com/media/sounds/fortnite-default-dance-bass-boosted.mp3",
      commandName: "defaultdance",
      tags: ["video game", "memes"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    icansmellyou: {
      title: "I Can Smell You",
      url: "https://www.myinstants.com/media/sounds/morgan-smell.mp3",
      commandName: "icansmellyou",
      tags: ["movie", "memes"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    peaceandlove: {
      title: "Peace and Love",
      url: "https://www.myinstants.com/media/sounds/peace-and-love.mp3",
      commandName: "peaceandlove",
      tags: ["memes", "sound effect"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    buzzer: {
      title: "Buzzer",
      url: "https://www.myinstants.com/media/sounds/family-fued-buzzer.mp3",
      commandName: "buzzer",
      tags: ["sound effect"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    iselledmywife: {
      title: "I Selled My Wife",
      url: "https://www.myinstants.com/media/sounds/i-selled-my-wife.mp3",
      commandName: "iselledmywife",
      tags: ["memes"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    e: {
      title: "E",
      url: "https://www.myinstants.com/media/sounds/e_meme.mp3",
      commandName: "e",
      tags: ["memes"],
      volume: 1.0,
      dateAdded: 1640565448705,
    },
    emotionaldamage: {
      title: "Emotional Damage",
      url: "https://www.myinstants.com/media/sounds/emotional-damage-x-pompeii.mp3",
      commandName: "emotionaldamage",
      tags: ["memes", "music"],
      volume: 1.0,
      dateAdded: 1645057516170,
    },
    minecraftskeleton: {
      title: "Minecraft Skeleton",
      url: "https://www.myinstants.com/media/sounds/skeleton-sounds-2.mp3",
      commandName: "minecraftskeleton",
      tags: ["sound effect", "minecraft", "video game"],
      volume: 1.0,
      dateAdded: 1645057516170,
    },
    minecraftghast: {
      title: "Minecraft Ghast",
      url: "https://www.myinstants.com/media/sounds/minecraft-scream2.mp3",
      commandName: "minecraftghast",
      tags: ["sound effect", "minecraft", "video game"],
      volume: 1.0,
      dateAdded: 1645057516170,
    },
    minecraftcreeper: {
      title: "Minecraft Creeper",
      url: "https://www.myinstants.com/media/sounds/creeper-sound.mp3",
      commandName: "minecraftcreeper",
      tags: ["sound effect", "minecraft", "video game"],
      volume: 1.0,
      dateAdded: 1645057516170,
    },
    minecraftcave: {
      title: "Minecraft Cave",
      url: "https://www.myinstants.com/media/sounds/minecraft-train-whistle-cave-sound.mp3",
      commandName: "minecraftcave",
      tags: ["sound effect", "minecraft", "video game"],
      volume: 1.0,
      dateAdded: 1645057516170,
    },
    minecraftxp: {
      title: "Minecraft XP",
      url: "https://www.myinstants.com/media/sounds/minecraft-levelup.mp3",
      commandName: "minecraftxp",
      tags: ["sound effect", "minecraft", "video game"],
      volume: 1.0,
      dateAdded: 1645057516170,
    },
    minecraftanthem: {
      title: "Minecraft Anthem Remix",
      url: "https://www.myinstants.com/media/sounds/minecraft-theme-trap-remix-holder.mp3",
      commandName: "minecraftanthem",
      tags: ["sound effect", "minecraft", "video game"],
      volume: 1.0,
      dateAdded: 1645057516170,
    },
  },
};
